@font-face {
    font-family: "tajawal";
    src: url("../font/tajawal/tajawal-black.ttf");
    font-weight: bolder;
}

@font-face {
    font-family: "tajawal";
    src: url("../font/tajawal/tajawal-bold.ttf");
    font-weight: bold;
}

@font-face {
    font-family: "tajawal";
    src: url("../font/tajawal/tajawal-medium.ttf");
    font-weight: normal;
}

@font-face {
    font-family: "tajawal";
    src: url("../font/tajawal/tajawal-light.ttf");
    font-weight: lighter;
}

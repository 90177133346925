// Bootstrap overrides

$primary: #30387b;
$secondary: #3d3d3d;
$danger: #df2f29;

.form-floating > label {
    left: unset !important;
    right: 0 !important;
}

// other variables

$text-color: #636363;
$background-color: #fafafa;
$card-background-color: #fcfcfc;

// styling for ng-select
@import "~@ng-select/ng-select/themes/default.theme.css";
// styling for bootstrap
@import '~ngx-toastr/toastr';
// bootstrap styling
@import "~bootstrap/scss/bootstrap";

.btn-group {
    border-radius: 10px !important;
    overflow: hidden;
}

.card {
    @extend .shadow-sm;
    border: none;
    border-radius: 25px;
    background-color: $card-background-color;
    transition: 0.4s all;

    .card-header {
        background-color: $card-background-color;
    }

    &.card-curved {
        border-radius: 15px;
    }

    &.card-curved-top {
        border-radius: 15px 15px 4px 4px;
    }

    &.card-curved-bottom {
        border-radius: 4px 4px 15px 15px;
    }
}

input, select, .form-control, .form-select, .ng-select-container {
    border-radius: 15px !important;
}

.btn {
    border-radius: 15px;

    &.btn-primary {
        background: rgb(77,89,163);
        background: linear-gradient(135deg, rgba(77,89,163,1) 0%, rgba(48,56,123,1) 100%);
    }
}

.NgxEditor__Wrapper  {
  overflow-x: scroll !important;
  border-radius: 15px !important;
  border: 1px solid #ced4da !important;
}

.cursor-pointer {
    cursor: pointer;
}

.hudhud-font-large {
    font-size: 1.4rem;
}

.hudhud-font-medium {
    font-size: 1.1rem;
}

.hudhud-font-small {
    font-size: 0.8rem;
}

body {
    font-family: "tajawal";
    background-color: $background-color;
    color: $text-color;
}

a {
    text-decoration: none;
}

.bg-gray {
    background-color: #ebeff0;
}

.btn-hudhud {
    background-color: $primary;
    color: white;
}

.btn-hudhud:hover {
    background-color: $secondary;
    color: white;
}

.bg-hudhud {
    background-color: $primary;
}

.text-hudhud {
    color: $primary;
}

.font-size-small {
    font-size: small;
}

::-webkit-scrollbar-track
{
	border: 3px solid white;
	// border color does not support transparent on scrollbar
	// border-color: transparent;
	background-color: #b2bec366;
}

::-webkit-scrollbar
{
	width: 3px;
	background-color: #dfe6e966;
}

::-webkit-scrollbar-thumb
{
	background-color: $secondary;
    opacity: 0.6;
	border-radius: 0px;
}

.hudhud-table {
    th, td {
        padding-top: 15px;
        padding-bottom: 15px;
    }
}

.pointer {
    cursor: pointer;
}

.news_image {
    border-radius: 10px;
    height: 4.5rem;
    width: 90%;
    background-color: #574b9652;
    margin-left: auto;
    margin-right: auto;
    background-size: cover;
    background-repeat: no-repeat;
}

.limited-scroll {
    max-height: 45vh;
    overflow: scroll !important;

    &.longer-limit {
        max-height: 65vh;
    }
}